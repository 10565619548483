import React from "react"

function SanJose() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img
          src={process.env.PUBLIC_URL + "/logo512.png"}
          alt='528 Logo'
          className='logo'
        />
        <h2>
          528 Entertainment
          <br />
          San Jose
        </h2>
        <a
          href='https://528-entertainment-sj.square.site'
          target='_blank'
          rel='noreferrer'
        >
          <h4>Click here for Tufting Workshop Reservation</h4>
        </a>
        <h3>
          📚🎭🤩🙌
          <br />
          直接预约/咨询请扫 WeChat QR Code
        </h3>
        <img
          src={process.env.PUBLIC_URL + "/sjqrcode.png"}
          alt='San Jose Store QR Code'
          className='qrcode'
        />
      </header>
    </div>
  )
}

export default SanJose
