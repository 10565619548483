// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyB137JytMlQmZAjrajj-IKm8cQYxXNMHIg',
  authDomain: 'react-home-528.firebaseapp.com',
  projectId: 'react-home-528',
  storageBucket: 'react-home-528.appspot.com',
  messagingSenderId: '1075855326712',
  appId: '1:1075855326712:web:1bb21b17262680d9c06a0d',
  measurementId: 'G-9QBCMP38D4',
};
