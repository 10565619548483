import React from "react"
import "./About.css"
function About() {
  return (
    <div className='About'>
      <div>
        <img
          src={process.env.PUBLIC_URL + "/logo512.png"}
          alt='528 Logo'
          className='About-logo'
        />
        <h1>Hello there,</h1>
        <h1>Welcome to 528 Entertainment</h1>
        <h2>Who we are?</h2>
        <h3>
          We provides facilities for recreational activities, namely, murder
          mystery and other gathering games such as Catan.
        </h3>
        <h3>We also provide facilities for social entertainment events.</h3>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/how/christmas.jpg"}
            alt='Christmas Event'
            className='About-image'
          />
          <img
            src={process.env.PUBLIC_URL + "/how/cosplay.jpg"}
            alt='Cosplay'
            className='About-image'
          />
          <img
            src={process.env.PUBLIC_URL + "/how/engagment.jpg"}
            alt='Engagment'
            className='About-image'
          />
          <img
            src={process.env.PUBLIC_URL + "/how/insta.jpg"}
            alt='Photos'
            className='About-image'
          />
          <img
            src={process.env.PUBLIC_URL + "/how/gaming.JPEG"}
            alt='Playing a Game'
            className='About-image'
          />
        </div>
        <h3>
          You can view all our game list.
          <div></div>
          <a href='/list20211128.JPG'>
            <img
              src={process.env.PUBLIC_URL + "/list20211128.JPG"}
              alt='Game Listing'
              className='About-list'
            />
          </a>
        </h3>
        <h3>
          For reservation, Please contact our customer service via Google
          Business Chat or WeChat.
        </h3>
        <img
          src={process.env.PUBLIC_URL + "/sjqrcode.png"}
          alt='San Jose Store QR Code'
          className='qrcode'
        />
      </div>
    </div>
  )
}
export default About
